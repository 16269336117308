/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .container {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        padding: 1rem 1rem;
        margin: 0.5rem 0;
        border-radius: 10px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.26);
        width: 100%;
    }

    .head {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }
    .head h3 {
        margin: 0;
    }

    .arrow-initial {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .arrow-right {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        animation: arrowRight 0.75s ease-out forwards;
    }
    @keyframes arrowRight {
        0% {
            -webkit-transform: rotate(45deg);
        }
        100% {
            -webkit-transform: rotate(-45deg);
        }
    }

    .arrow-down {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        animation: arrowDown 0.75s ease-out forwards;
    }
    @keyframes arrowDown {
        0% {
            -webkit-transform: rotate(-45deg);
        }
        100% {
            -webkit-transform: rotate(45deg);
        }
    }

    .job {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 85rem;
    }
    .job__open {
        overflow: hidden;
        animation: openJob 0.75s ease-out forwards;
    }
    @keyframes openJob {
        0% {
            height: 0;
        }
        100% {
            height: 85rem;
        }
    }
    .job__close {
        overflow: hidden;
        animation: closeJob 0.75s ease-out forwards;
    }
    @keyframes closeJob {
        0% {
            height: 85rem;
        }
        100% {
            height: 0;
        }
    }

    .description__header {
        color: #b07531;
        padding: 0;
        margin: 1rem 0 0.25rem 0;
    }
    .description-text {
        padding: 0;
        margin: 0;
    }

    .qualification {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .qualification__header {
        color: #b07531;
        padding: 0;
        margin: 1rem 0 0.25rem 0;
    }
    .qualification-text {
        padding: 0;
        margin: 0;
    }
    .qualification-lists {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    .qualification-list {
        width: 100%;
        margin: 0;
        margin-bottom: 1rem;
        color: #344f6e;
    }
    .qualification-list__header {
        text-align: start;
        margin: 0;
        margin-bottom: 0.25rem;
        padding: 0;
        color: #344f6e;
    }
    .qualification-list__element {
        margin: 0.25rem 0;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: buttonLeave 0.3s ease-in forwards;
    }
    @keyframes buttonLeave {
        0% {
            color: black;
            background: #344f6e;
            border: 0;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .container {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        padding: 1rem 1rem;
        margin: 0.5rem 0;
        border-radius: 10px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.26);
        width: 100%;
    }

    .head {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }
    .head h3 {
        margin: 0;
    }

    .arrow-initial {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .arrow-right {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        animation: arrowRight 0.75s ease-out forwards;
    }
    @keyframes arrowRight {
        0% {
            -webkit-transform: rotate(45deg);
        }
        100% {
            -webkit-transform: rotate(-45deg);
        }
    }

    .arrow-down {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        animation: arrowDown 0.75s ease-out forwards;
    }
    @keyframes arrowDown {
        0% {
            -webkit-transform: rotate(-45deg);
        }
        100% {
            -webkit-transform: rotate(45deg);
        }
    }

    .job {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 90rem;
    }
    .job__open {
        overflow: hidden;
        animation: openJob 0.75s ease-out forwards;
    }
    @keyframes openJob {
        0% {
            height: 0;
        }
        100% {
            height: 90rem;
        }
    }
    .job__close {
        overflow: hidden;
        animation: closeJob 0.75s ease-out forwards;
    }
    @keyframes closeJob {
        0% {
            height: 90rem;
        }
        100% {
            height: 0;
        }
    }

    .description__header {
        color: #b07531;
        padding: 0;
        margin: 1rem 0 0.25rem 0;
    }
    .description-text {
        padding: 0;
        margin: 0;
    }

    .qualification {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .qualification__header {
        color: #b07531;
        padding: 0;
        margin: 1rem 0 0.25rem 0;
    }
    .qualification-text {
        padding: 0;
        margin: 0;
    }
    .qualification-lists {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    .qualification-list {
        width: 100%;
        margin: 0;
        margin-bottom: 1rem;
        color: #344f6e;
    }
    .qualification-list__header {
        text-align: start;
        margin: 0;
        margin-bottom: 0.25rem;
        padding: 0;
        color: #344f6e;
    }
    .qualification-list__element {
        margin: 0.25rem 0;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: buttonLeave 0.3s ease-in forwards;
    }
    @keyframes buttonLeave {
        0% {
            color: black;
            background: #344f6e;
            border: 0;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .container {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1rem;
        margin: 0.75rem 0;
        border-radius: 10px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.26);
        width: 100%;
    }

    .head {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }
    .head h3 {
        margin: 0;
    }

    .arrow-initial {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .arrow-right {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        animation: arrowRight 0.75s ease-out forwards;
    }
    @keyframes arrowRight {
        0% {
            -webkit-transform: rotate(45deg);
        }
        100% {
            -webkit-transform: rotate(-45deg);
        }
    }

    .arrow-down {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        animation: arrowDown 0.75s ease-out forwards;
    }
    @keyframes arrowDown {
        0% {
            -webkit-transform: rotate(-45deg);
        }
        100% {
            -webkit-transform: rotate(45deg);
        }
    }

    .job {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 60rem;
    }
    .job__open {
        overflow: hidden;
        animation: openJob 0.75s ease-out forwards;
    }
    @keyframes openJob {
        0% {
            height: 0;
        }
        100% {
            height: 60rem;
        }
    }
    .job__close {
        overflow: hidden;
        animation: closeJob 0.75s ease-out forwards;
    }
    @keyframes closeJob {
        0% {
            height: 60rem;
        }
        100% {
            height: 0;
        }
    }

    .description__header {
        color: #b07531;
        padding: 0;
        margin: 2rem 0 0.5rem 0;
    }
    .description-text {
        padding: 0;
        margin: 0;
    }

    .qualification {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .qualification__header {
        color: #b07531;
        padding: 0;
        margin: 2rem 0 0.5rem 0;
    }
    .qualification-text {
        padding: 0;
        margin: 0;
        margin-bottom: 3rem;
    }
    .qualification-lists {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }
    .qualification-list {
        width: 50%;
        margin: 0;
        margin-bottom: 1rem;
        color: #344f6e;
    }
    .qualification-list__header {
        text-align: start;
        margin: 0;
        margin-bottom: 0.25rem;
        padding: 0;
        color: #344f6e;
    }
    .qualification-list__element {
        margin: 0.5rem 0;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: buttonLeave 0.3s ease-in forwards;
    }
    @keyframes buttonLeave {
        0% {
            color: black;
            background: #344f6e;
            border: 0;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .container {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 1rem;
        margin: 0.75rem 0;
        border-radius: 10px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.26);
        width: 100%;
    }

    .head {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }
    .head h3 {
        margin: 0;
    }

    .arrow-initial {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .arrow-right {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        animation: arrowRight 0.75s ease-out forwards;
    }
    @keyframes arrowRight {
        0% {
            -webkit-transform: rotate(45deg);
        }
        100% {
            -webkit-transform: rotate(-45deg);
        }
    }

    .arrow-down {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        animation: arrowDown 0.75s ease-out forwards;
    }
    @keyframes arrowDown {
        0% {
            -webkit-transform: rotate(-45deg);
        }
        100% {
            -webkit-transform: rotate(45deg);
        }
    }

    .job {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 55rem;
    }
    .job__open {
        overflow: hidden;
        animation: openJob 0.75s ease-out forwards;
    }
    @keyframes openJob {
        0% {
            height: 0;
        }
        100% {
            height: 55rem;
        }
    }
    .job__close {
        overflow: hidden;
        animation: closeJob 0.75s ease-out forwards;
    }
    @keyframes closeJob {
        0% {
            height: 55rem;
        }
        100% {
            height: 0;
        }
    }

    .description__header {
        color: #b07531;
        padding: 0;
        margin: 2rem 0 0.5rem 0;
    }
    .description-text {
        padding: 0;
        margin: 0;
    }

    .qualification {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .qualification__header {
        color: #b07531;
        padding: 0;
        margin: 2rem 0 0.5rem 0;
    }
    .qualification-text {
        padding: 0;
        margin: 0;
        margin-bottom: 3rem;
    }
    .qualification-lists {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }
    .qualification-list {
        width: 50%;
        margin: 0;
        margin-bottom: 1rem;
        color: #344f6e;
    }
    .qualification-list__header {
        text-align: start;
        margin: 0;
        margin-bottom: 0.25rem;
        padding: 0;
        color: #344f6e;
    }
    .qualification-list__element {
        margin: 0.5rem 0;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: buttonLeave 0.3s ease-in forwards;
    }
    @keyframes buttonLeave {
        0% {
            color: black;
            background: #344f6e;
            border: 0;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
}
