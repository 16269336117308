/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .siteHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .siteHead__head {
        color: #b07531;
        margin: 0;
        margin-bottom: 0.2rem;
        font-size: 1.75rem;
        text-align: center;
    }
    .siteHead__text {
        margin: 0;
        padding: 0;
        text-align: center;
        color: white;
    }

    .siteHead__quote {
        width: 100%;
        padding: 1.5rem;
        margin: 0;
        text-align: center;
        color: #367bc9;
        border-radius: 5px;
        box-shadow: 0 2px 8px #353e46;
        background: #353e46;
    }

    .btn {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn:active:enabled {
        background: #b07531;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveButton 0.3s ease-in forwards;
    }
    @keyframes leaveButton {
        0% {
            color: black;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: #b0753100;
        }
    }
    .btn-hovered:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn-hovered:active:enabled {
        background: #b07531;
        color: black;
    }

    .serviceBtn {
        font: inherit;
        background: #b07531;
        border: 1px solid #b07531;
        color: white;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .serviceBtn:hover {
        animation: changeServiceButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeServiceButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .serviceBtn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .serviceBtn-hovered {
        font: inherit;
        background: #b07531;
        border: 1px solid #b07531;
        color: white;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveServiceButton 0.3s ease-in forwards;
    }
    @keyframes leaveServiceButton {
        0% {
            color: black;
            background: #344f6e;
            border-color: #344f6e;
        }
        100% {
            color: white;
            background: #b07531;
            border-color: #b07531;
        }
    }
    .serviceBtn-hovered:hover {
        animation: hoverServiceButton 0.3s ease-out forwards;
    }
    @keyframes hoverServiceButton {
        0% {
            color: white;
            background: #b07531;
            border-color: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border-color: #344f6e;
        }
    }
    .serviceBtn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .service {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .service__head {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.2rem;
        text-align: center;
    }
    .service__text {
        margin: 0;
    }

    .projects {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0;
        margin: 0;
        width: 90%;
    }
    .projects__head {
        color: #b07531;
        margin: 0;
        margin-bottom: 0.2rem;
        padding: 0;
    }
    .projects__text {
        padding: 0;
        margin: 0;
        color: white;
    }

    .infos {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .infos__head {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .infos-partner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: 0;
        margin-top: 2rem;
        width: 105%;
        height: auto;
    }
    .infos-partner__image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: transparent;
        margin: 0.75rem 0;
        width: 100%;
        height: 6rem;
        padding: 2rem 3rem;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
    }
    .infos-partner__image img {
        width: auto;
        height: 5rem;
        margin: 0;
        border-radius: 10px;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .siteHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .siteHead__head {
        color: #b07531;
        margin: 0;
        margin-bottom: 0.2rem;
        font-size: 1.75rem;
        text-align: center;
    }
    .siteHead__text {
        margin: 0;
        padding: 0;
        text-align: center;
        color: white;
    }

    .siteHead__quote {
        width: 100%;
        padding: 1.5rem;
        margin: 0;
        text-align: center;
        color: #367bc9;
        border-radius: 5px;
        box-shadow: 0 2px 8px #353e46;
        background: #353e46;
    }

    .btn {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn:active:enabled {
        background: #b07531;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveButton 0.3s ease-in forwards;
    }
    @keyframes leaveButton {
        0% {
            color: black;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: #b0753100;
        }
    }
    .btn-hovered:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn-hovered:active:enabled {
        background: #b07531;
        color: black;
    }

    .serviceBtn {
        font: inherit;
        background: #b07531;
        border: 1px solid #b07531;
        color: white;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .serviceBtn:hover {
        animation: changeServiceButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeServiceButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .serviceBtn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .serviceBtn-hovered {
        font: inherit;
        background: #b07531;
        border: 1px solid #b07531;
        color: white;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveServiceButton 0.3s ease-in forwards;
    }
    @keyframes leaveServiceButton {
        0% {
            color: black;
            background: #344f6e;
            border-color: #344f6e;
        }
        100% {
            color: white;
            background: #b07531;
            border-color: #b07531;
        }
    }
    .serviceBtn-hovered:hover {
        animation: hoverServiceButton 0.3s ease-out forwards;
    }
    @keyframes hoverServiceButton {
        0% {
            color: white;
            background: #b07531;
            border-color: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border-color: #344f6e;
        }
    }
    .serviceBtn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .service {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .service__head {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.2rem;
        text-align: center;
    }
    .service__text {
        margin: 0;
    }

    .projects {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0;
        margin: 0;
        width: 90%;
    }
    .projects__head {
        color: #b07531;
        margin: 0;
        margin-bottom: 0.2rem;
        padding: 0;
    }
    .projects__text {
        padding: 0;
        margin: 0;
        color: white;
    }

    .infos {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .infos__head {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .infos-partner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: 0;
        margin-top: 2rem;
        width: 100%;
        height: auto;
    }
    .infos-partner__image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: transparent;
        margin: 0.75rem 0;
        width: 100%;
        height: 6rem;
        padding: 2rem 3rem;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
    }
    .infos-partner__image img {
        width: auto;
        height: 5rem;
        margin: 0;
        border-radius: 10px;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .siteHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 70%;
    }
    .siteHead__head {
        color: #b07531;
        margin: 0;
        margin-bottom: 0.2rem;
        font-size: 1.75rem;
        text-align: center;
    }
    .siteHead__text {
        margin: 0;
        padding: 0;
        text-align: center;
        color: white;
    }

    .siteHead__quote {
        width: 100%;
        padding: 1.5rem;
        margin: 0;
        text-align: center;
        color: #367bc9;
        border-radius: 5px;
        box-shadow: 0 2px 8px #353e46;
        background: #353e46;
    }

    .btn {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn:active:enabled {
        background: #b07531;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveButton 0.3s ease-in forwards;
    }
    @keyframes leaveButton {
        0% {
            color: black;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: #b0753100;
        }
    }
    .btn-hovered:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn-hovered:active:enabled {
        background: #b07531;
        color: black;
    }

    .serviceBtn {
        font: inherit;
        background: #b07531;
        border: 1px solid #b07531;
        color: white;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .serviceBtn:hover {
        animation: changeServiceButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeServiceButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .serviceBtn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .serviceBtn-hovered {
        font: inherit;
        background: #b07531;
        border: 1px solid #b07531;
        color: white;
        margin: 1.5rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveServiceButton 0.3s ease-in forwards;
    }
    @keyframes leaveServiceButton {
        0% {
            color: black;
            background: #344f6e;
            border-color: #344f6e;
        }
        100% {
            color: white;
            background: #b07531;
            border-color: #b07531;
        }
    }
    .serviceBtn-hovered:hover {
        animation: hoverServiceButton 0.3s ease-out forwards;
    }
    @keyframes hoverServiceButton {
        0% {
            color: white;
            background: #b07531;
            border-color: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border-color: #344f6e;
        }
    }
    .serviceBtn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .service {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .service__head {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.2rem;
        text-align: center;
    }
    .service__text {
        margin: 0;
    }

    .projects {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0;
        margin: 0;
        width: 90%;
    }
    .projects__head {
        color: #b07531;
        margin: 0;
        margin-bottom: 0.2rem;
        padding: 0;
    }
    .projects__text {
        padding: 0;
        margin: 0;
        color: white;
    }

    .infos {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .infos__head {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .infos-partner {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-evenly;
        margin: 0;
        margin-top: 2rem;
        width: 100%;
        height: auto;
    }
    .infos-partner__image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: transparent;
        margin: 1rem 0;
        width: 47.5%;
        height: 7rem;
        padding: 1rem 2rem;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
    }
    .infos-partner__image img {
        width: auto;
        height: 100%;
        margin: 0;
        border-radius: 10px;
    }
}

/* PCs / Laptops ******************************************************************************************************************************/
@media (min-width: 55rem) {
    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }
    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }
    .siteHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 60%;
        max-width: 35rem;
    }
    .siteHead__head {
        color: #b07531;
        text-align: center;
        font-size: 2rem;
        line-height: 2.5rem;
        margin: 0;
        margin-bottom: 3rem;
    }
    .siteHead__text {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 1.2rem;
        color: white;
    }

    .btn {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 3rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn:active:enabled {
        background: #b07531;
        color: white;
    }
    .btn-hovered {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 3rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveButton 0.3s ease-in forwards;
    }
    @keyframes leaveButton {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: #b0753100;
        }
    }
    .btn-hovered:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:active:enabled {
        background: #b07531;
        color: white;
    }

    .serviceBtn {
        font: inherit;
        background: #b07531;
        border: 1px solid #b07531;
        color: white;
        margin: 3rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .serviceBtn:hover {
        animation: changeServiceButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeServiceButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .serviceBtn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .serviceBtn-hovered {
        font: inherit;
        background: #b07531;
        border: 1px solid #b07531;
        color: white;
        margin: 3rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveServiceButton 0.3s ease-in forwards;
    }
    @keyframes leaveServiceButton {
        0% {
            color: black;
            background: #344f6e;
            border-color: #344f6e;
        }
        100% {
            color: white;
            background: #b07531;
            border-color: #b07531;
        }
    }
    .serviceBtn-hovered:hover {
        animation: hoverServiceButton 0.3s ease-out forwards;
    }
    @keyframes hoverServiceButton {
        0% {
            color: white;
            background: #b07531;
            border-color: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border-color: #344f6e;
        }
    }
    .serviceBtn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .siteHead__quote {
        width: 100%;
        padding: 3rem;
        margin: 0;
        text-align: center;
        color: #367bc9;
        border-radius: 5px;
        box-shadow: 0 2px 8px #353e46;
        background: #353e46;
    }

    .service {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 90%;
        max-width: 60rem;
    }
    .service__head {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .service__text {
        margin: 0;
    }

    .projects {
        display: flex;
        background: transparent;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 90%;
        padding: 0;
        margin: 0;
        max-width: 60rem;
    }
    .projects__head {
        color: #b07531;
        margin: 0;
        margin-bottom: 0.5rem;
        padding: 0;
    }
    .projects__text {
        margin: 0;
        padding: 0;
        color: white;
    }

    .infos {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        width: 90%;
        max-width: 60rem;
        margin: 0;
        padding: 0;
    }
    .infos__head {
        margin: 0;
        margin-bottom: 1rem;
        color: #344f6e;
    }

    .infos-partner {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-evenly;
        margin: 0;
        margin-top: 2rem;
        width: 100%;
        height: auto;
    }
    .infos-partner__image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: transparent;
        margin: 1rem 0;
        width: 40%;
        height: 7rem;
        padding: 1rem 2rem;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
    }
    .infos-partner__image img {
        width: auto;
        height: 100%;
        margin: 0;
        border-radius: 10px;
    }
}
