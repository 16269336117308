/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }

    .lds-ring {
        display: inline-block;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 49px;
        height: 49px;
        margin: 7px;
        border: 7px solid #367bc9;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #367bc9 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .container {
        display: flex;
        width: 105%;
        background: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border-radius: 5px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
        padding: 1rem 1rem;
    }
    .container h3 {
        color: #344f6e;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .projects {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        margin: 0;
        margin-top: 1rem;
        padding: 0.75rem 2rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover {
        animation: changeButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .btn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        margin: 0;
        margin-top: 1rem;
        padding: 0.75rem 2rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:hover {
        animation: changeButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }

    .lds-ring {
        display: inline-block;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 49px;
        height: 49px;
        margin: 7px;
        border: 7px solid #367bc9;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #367bc9 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .container {
        display: flex;
        width: 100%;
        background: white;
        border-radius: 10px;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
        padding: 1rem 1rem;
    }
    .container h3 {
        color: #344f6e;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .projects {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        margin: 0;
        margin-top: 1rem;
        padding: 0.75rem 2rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover {
        animation: changeButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .btn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        margin: 0;
        margin-top: 1rem;
        padding: 0.75rem 2rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:hover {
        animation: changeButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }

    .lds-ring {
        display: inline-block;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #367bc9;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #367bc9 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .container {
        display: flex;
        width: 100%;
        background: white;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
        padding: 2rem 2rem;
    }
    .container h3 {
        color: #344f6e;
        margin: 0;
        padding: 0;
    }

    .projects {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        margin: 0;
        margin-top: 1rem;
        padding: 0.75rem 2rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover {
        animation: changeButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .btn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        margin: 1rem 0;
        padding: 0.75rem 2rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:hover {
        animation: changeButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }

    .lds-ring {
        display: inline-block;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 101;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #367bc9;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #367bc9 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .container {
        display: flex;
        width: 100%;
        background: white;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
        padding: 3rem 1rem;
  
    }
    .container h3 {
        color: #344f6e;
        margin: 0;
        padding: 0;
    }

    .projects {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        margin: 0;
        margin-top: 1rem;
        padding: 0.5rem 2rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover {
        animation: changeButtonColor 0.3s ease-out forwards;
    }
    @keyframes changeButtonColor {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .btn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }

    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        margin: 0;
        margin-top: 1rem;
        padding: 0.5rem 2rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveButton 0.3s ease-in forwards;
    }
    @keyframes leaveButton {
        0% {
            color: black;
            background: #344f6e;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
        }
    }
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
}
