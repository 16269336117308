/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem){
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        background: #344F6E;
    }
    
    .background h1 {
        margin: 0;
        padding: 3rem 0;
        color: #b07531;
    }
    
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        background: #344F6E;
    }
    
    .background h1 {
        margin: 0;
        padding: 3rem 0;
        color: #b07531;
    }
    
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .background {
        display: flex;
        justify-content: space-around;
        margin: auto;
        background: #344F6E;
    }
    
    .background h1{
        color: #b07531;
        margin: 0;
        padding: 3rem;
    }
}


/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) { 
    .background {
        display: flex;
        justify-content: space-around;
        margin: auto;
        background: #344F6E;
    }
    
    .background h1{
        color: #b07531;
        margin: 0;
        padding: 3rem;
    }
}