/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0rem;
        padding: 1rem;
        width: 100%;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 1rem;
    }
    .description h4 {
        margin: 0;
        margin-top: 0.5rem;
        color: #b07531;
    }
    .description h5 {
        margin: 0;
        padding: 0;
        color: #344f6e;
    }
    .description p {
        margin: 0;
        margin-bottom: 1rem;
        padding: 0;
    }

    .image {
        display: flex;
        justify-content: center;
        background: transparent;
        border-radius: 5px;
        width: 20rem;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.26);
        display: flex;
    }
    .image img {
        width: 100%;
        margin: 0;
        border-radius: 5px;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0rem;
        padding: 1rem;
        width: 100%;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 1rem;
    }
    .description h4 {
        margin: 0;
        margin-top: 0.5rem;
        color: #b07531;
    }
    .description h5 {
        margin: 0;
        padding: 0;
        color: #344f6e;
    }
    .description p {
        margin: 0;
        margin-bottom: 1rem;
        padding: 0;
    }

    .image {
        display: flex;
        justify-content: center;
        background: transparent;
        border-radius: 5px;
        width: 20rem;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.26);
        display: flex;
    }
    .image img {
        width: 100%;
        margin: 0;
        border-radius: 5px;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        margin: 0rem;
        padding: 1rem;
        width: 100%;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        margin-left: 1rem;
        width: 55%;
    }
    .description h4 {
        margin: 0;
        color: #b07531;
    }
    .description h5 {
        margin: 0;
        padding: 0;
        color: #344f6e;
    }
    .description p {
        margin: 0;
    }

    .image {
        display: flex;
        justify-content: center;
        background: transparent;
        border-radius: 5px;
        width: 17.5rem;
        margin: 0;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.26);
        display: flex;
    }
    .image img {
        width: 100%;
        margin: 0;
        border-radius: 5px;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        margin: 0rem;
        padding: 1rem;
        width: 100%;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        margin-left: 1rem;
        width: 55%;
    }
    .description h4 {
        margin: 0;
        color: #b07531;
    }
    .description h5 {
        margin: 0;
        padding: 0;
        color: #344f6e;
    }
    .description p {
        margin: 0;
    }

    .image {
        display: flex;
        justify-content: center;
        background: transparent;
        border-radius: 5px;
        width: 17.5rem;
        margin: 0;
        box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.26);
        display: flex;
    }
    .image img {
        width: 100%;
        margin: 0;
        border-radius: 5px;
    }
}
