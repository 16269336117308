.dot {
    height: 10px;
    width: 10px;
    background: white;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #344F6E;
    margin: 0 2px;
}
.active {
    background: #344F6E;
}