/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
        background: #344f6e;
    }
    .foot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 90%;
    }
    .foot h2 {
        color: #b07531;
        margin: 0;
        padding: 0;
    }
    .foot p {
        margin: 0.5rem 0;
        padding: 0;
        color: white;
        text-align: center;
    }

    .btn {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 0;
        margin-top: 1rem;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn:active:enabled {
        background: #b07531;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveButton 0.3s ease-in forwards;
    }
    @keyframes leaveButton {
        0% {
            color: black;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: #b0753100;
        }
    }
    .btn-hovered:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn-hovered:active:enabled {
        background: #b07531;
        color: black;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
        background: #344f6e;
    }
    .foot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 90%;
    }
    .foot h2 {
        color: #b07531;
        margin: 0;
        padding: 0;
    }
    .foot p {
        margin: 0.5rem 0;
        padding: 0;
        color: white;
        text-align: center;
    }

    .btn {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 0;
        margin-top: 1rem;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn:active:enabled {
        background: #b07531;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveButton 0.3s ease-in forwards;
    }
    @keyframes leaveButton {
        0% {
            color: black;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: #b0753100;
        }
    }
    .btn-hovered:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn-hovered:active:enabled {
        background: #b07531;
        color: black;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        background: #344f6e;
    }
    .foot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 50%;
    }
    .foot h2 {
        color: #b07531;
        margin: 0;
        padding: 0;
    }
    .foot p {
        margin: 1rem 0;
        padding: 0;
        text-align: center;
        color: white;
    }

    .btn {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn:active:enabled {
        background: #b07531;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveButton 0.3s ease-in forwards;
    }
    @keyframes leaveButton {
        0% {
            color: black;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: #b0753100;
        }
    }
    .btn-hovered:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn-hovered:active:enabled {
        background: #b07531;
        color: black;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        background: #344f6e;
    }
    .foot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 30%;
        max-width: 25rem;
    }
    .foot h2 {
        color: #b07531;
        margin: 0;
        padding: 0;
    }
    .foot p {
        margin: 1rem 0;
        padding: 0;
        text-align: center;
        color: white;
    }

    .btn {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn:active:enabled {
        background: #b07531;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        border: 1px solid #b07531;
        background: #b0753100;
        color: #b07531;
        margin: 1rem auto;
        padding: 0.75rem 3rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: leaveButton 0.3s ease-in forwards;
    }
    @keyframes leaveButton {
        0% {
            color: black;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: #b0753100;
        }
    }
    .btn-hovered:hover:enabled {
        animation: hoverButton 0.3s ease-out forwards;
    }
    @keyframes hoverButton {
        0% {
            color: #b07531;
            background: #b0753100;
        }
        100% {
            color: black;
            background: #b07531;
        }
    }
    .btn-hovered:active:enabled {
        background: #b07531;
        color: black;
    }
}
