/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .features {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 95%;
        height: 25rem;
    }
    .features h4 {
        margin: 0.1rem 0;
        color: #b07531;
    }
    .features-list {
        margin: 0;
        margin-bottom: 1rem;
        padding: 0;
        padding-left: 1rem;
    }
    .light {
        color: white;
    }
    .dark {
        color: black;
    }
    .features-icons {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin: 0;
        margin-bottom: 0.25rem;
    }
    .features-icons__element {
        vertical-align: center;
        margin: 0 0.1rem;
        color: #344f6e;
    }

    .image-swiper__dark {
        border-radius: 5px;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.26);
        background: transparent;
        color: black;
        margin: 0;
        padding: 1rem 2.5rem;
        width: 20rem;
        height: 18rem;
    }
    .image-swiper__light {
        border-radius: 5px;
        box-shadow: 1px 2px 4px rgba(255, 255, 255, 0.2);
        background: transparent;
        color: white;
        margin: 0;
        padding: 1rem 2.5rem;
        width: 20rem;
        height: 18rem;
    }
    .image {
        display: flex;
        align-items: center;
        width: 15rem;
        height: 15rem;
    }
    .image img {
        width: 100%;
        margin: 0;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .features {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 95%;
        height: 25rem;
    }
    .features h4 {
        margin: 0.1rem 0;
        color: #b07531;
    }
    .features-list {
        margin: 0;
        margin-bottom: 1rem;
        padding: 0;
        padding-left: 1rem;
    }
    .light {
        color: white;
    }
    .dark {
        color: black;
    }
    .features-icons {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .features-icons__element {
        vertical-align: center;
        margin: 0 0.1rem;
        color: #344f6e;
    }

    .image-swiper__dark {
        border-radius: 5px;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.26);
        background: transparent;
        color: black;
        margin: 0;
        padding: 1rem 2.5rem;
        width: 20rem;
        height: 18rem;
    }
    .image-swiper__light {
        border-radius: 5px;
        box-shadow: 1px 2px 4px rgba(255, 255, 255, 0.2);
        background: transparent;
        color: white;
        margin: 0;
        padding: 1rem 2.5rem;
        width: 20rem;
        height: 18rem;
    }
    .image {
        display: flex;
        align-items: center;
        width: 15rem;
        height: 15rem;
    }
    .image img {
        width: 100%;
        margin: 0;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    .features {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0;
        margin-right: 1rem;
        width: 55%;
    }
    .features h4 {
        margin: 0.25rem 0;
        color: #b07531;
    }
    .features-block {
        width: 100%;
    }
    .features-list {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-left: 1rem;
    }
    .light {
        color: white;
    }
    .dark {
        color: black;
    }
    .features-icons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin: 0;
        margin-top: 0.5rem;
    }
    .features-icons__element {
        vertical-align: center;
        margin: 0 0.1rem;
        color: #344f6e;
    }

    .image-swiper__dark {
        border-radius: 5px;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.26);
        background: transparent;
        color: black;
        margin: 0;
        padding: 1rem 2.5rem;
        width: 20rem;
        height: 18rem;
    }
    .image-swiper__light {
        border-radius: 5px;
        box-shadow: 1px 2px 4px rgba(255, 255, 255, 0.2);
        background: transparent;
        color: white;
        margin: 0;
        padding: 1rem 2.5rem;
        width: 20rem;
        height: 18rem;
    }
    .image {
        display: flex;
        align-items: center;
        width: 15rem;
        height: 15rem;
    }
    .image img {
        width: 100%;
        margin: 0;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    .features {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0;
        margin-right: 1rem;
        width: 55%;
    }
    .features h4 {
        margin: 0.25rem 0;
        color: #b07531;
    }
    .features-block {
        width: 100%;
    }
    .features-list {
        width: 100%;
        margin: 0;
        padding: 0;
        padding-left: 1rem;
    }
    .light {
        color: white;
    }
    .dark {
        color: black;
    }
    .features-icons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin: 0;
        margin-top: 1rem;
    }
    .features-icons__element {
        vertical-align: center;
        margin: 0 0.1rem;
        color: #344f6e;
    }
    .image-swiper__dark {
        border-radius: 5px;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.26);
        background: transparent;
        color: black;
        margin: 0;
        padding: 1rem 2.5rem;
        width: 20rem;
        height: 18rem;
    }
    .image-swiper__light {
        border-radius: 5px;
        box-shadow: 1px 2px 4px rgba(255, 255, 255, 0.2);
        background: transparent;
        color: white;
        margin: 0;
        padding: 1rem 2.5rem;
        width: 20rem;
        height: 18rem;
    }
    .image {
        display: flex;
        align-items: center;
        width: 15rem;
        height: 15rem;
    }
    .image img {
        width: 100%;
        margin: 0;
    }
}
