/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }
    .backdrop__open {
        animation: openBackdrop 0.4s ease-out forwards;
    }
    @keyframes openBackdrop {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .backdrop__close {
        animation: closeBackdrop 0.4s ease-in forwards;
    }
    @keyframes closeBackdrop {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .backdrop-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 103;
        background: rgba(0, 0, 0, 0.75);
    }

    .lds-ring {
        display: inline-block;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 104;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #367bc9;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #367bc9 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        box-sizing: border-box;
        top: 30%;
        left: 5%;
        width: 90%;
        z-index: 105;
        padding: 3rem;
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        border-radius: 10px;
    }
    .card__open {
        animation: openCard 0.6s ease-out forwards;
    }
    @keyframes openCard {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        50% {
            opacity: 1;
            transform: translateY(90%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .card__close {
        animation: closeCard 0.6s ease-out forwards;
    }
    @keyframes closeCard {
        0% {
            opacity: 1;
            transform: translateY(0);
        }
        50% {
            opacity: 0.8;
            transform: translateY(60%);
        }
        100% {
            opacity: 0;
            transform: translateY(-100%);
        }
    }

    .card p {
        color: #344f6e;
    }

    .form {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 101;
        top: 5%;
        height: 90%;
        left: 2.5%;
        width: 95%;
        padding: 1rem 2rem;
        margin: 0;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
        background: white;
    }
    .form__open {
        animation: openCard 0.6s ease-out forwards;
    }
    .form__close {
        animation: closeCard 0.6s ease-in forwards;
    }

    .form-control {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .form-control input,
    .form-control textarea {
        font-family: inherit;
        font-size: inherit;
        width: 100%;
        resize: none;
        padding: 0.5rem;
        border-radius: 4px;
        border: 1px solid #ccc;
    }
    .form-control label {
        color: #344f6e;
        font-weight: bold;
    }
    .form-control input:focus,
    .form-control textarea:focus {
        outline: none;
        border-color: #344f6e;
        background-color: #344f6e25;
    }

    .form-control__documents {
        color: #344f6e;
        font-weight: bold;
    }

    .invalid input,
    .invalid textarea {
        border: 1px solid #b40e0e;
        background-color: #fddddd;
    }
    .invalid input:focus,
    .invalid textarea:focus {
        border-color: #ff8800;
        background-color: #fbe8d2;
    }
    .error-text {
        color: #b40e0e;
        margin: 0;
        width: 100%;
        font-size: 0.75rem;
    }

    #message {
        height: 10rem;
        margin: 0;
    }
    #check {
        width: auto;
        margin: 0;
    }

    .privacy {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .privacy p {
        margin: 0 0.5rem;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 2rem;
        margin: 0;
        margin-top: 0.5rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: white;
            background: #344f6e;
        }
    }
    .btn:active:enabled {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn:disabled,
    .btn[disabled] {
        border: 1px solid #344f6e50;
        background-color: #344f6e25;
        color: #344f6e50;
    }
    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 3rem;
        margin: 0;
        margin-top: 0.5rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:enabled {
        animation: buttonLeave 0.3s ease-out forwards;
    }
    @keyframes buttonLeave {
        0% {
            color: black;
            background: #344f6e;
            border: 0;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover:enabled {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn-hovered:active:enabled {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn-hovered:disabled,
    .btn-hovered[disabled] {
        border: 1px solid #344f6e50;
        background-color: #344f6e25;
        color: #344f6e50;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }
    .backdrop__open {
        animation: openBackdrop 0.4s ease-out forwards;
    }
    @keyframes openBackdrop {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .backdrop__close {
        animation: closeBackdrop 0.4s ease-in forwards;
    }
    @keyframes closeBackdrop {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .backdrop-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 103;
        background: rgba(0, 0, 0, 0.75);
    }

    .lds-ring {
        display: inline-block;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 104;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #367bc9;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #367bc9 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        box-sizing: border-box;
        top: 30%;
        left: 5%;
        width: 90%;
        z-index: 105;
        padding: 3rem;
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        border-radius: 10px;
    }
    .card__open {
        animation: openCard 0.6s ease-out forwards;
    }
    @keyframes openCard {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        50% {
            opacity: 1;
            transform: translateY(90%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .card__close {
        animation: closeCard 0.6s ease-out forwards;
    }
    @keyframes closeCard {
        0% {
            opacity: 1;
            transform: translateY(0);
        }
        50% {
            opacity: 0.8;
            transform: translateY(60%);
        }
        100% {
            opacity: 0;
            transform: translateY(-100%);
        }
    }

    .card p {
        color: #344f6e;
    }

    .form {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 101;
        top: 10%;
        height: 80%;
        left: 2.5%;
        width: 95%;
        padding: 2rem 2rem;
        margin: 0;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
        background: white;
    }
    .form__open {
        animation: openCard 0.6s ease-out forwards;
    }
    .form__close {
        animation: closeCard 0.6s ease-in forwards;
    }

    .form-control {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .form-control input,
    .form-control textarea {
        font-family: inherit;
        font-size: inherit;
        width: 100%;
        resize: none;
        padding: 0.5rem;
        border-radius: 4px;
        border: 1px solid #ccc;
    }
    .form-control label {
        color: #344f6e;
        font-weight: bold;
    }
    .form-control input:focus,
    .form-control textarea:focus {
        outline: none;
        border-color: #344f6e;
        background-color: #344f6e25;
    }

    .form-control__documents {
        color: #344f6e;
        font-weight: bold;
    }

    .invalid input,
    .invalid textarea {
        border: 1px solid #b40e0e;
        background-color: #fddddd;
    }
    .invalid input:focus,
    .invalid textarea:focus {
        border-color: #ff8800;
        background-color: #fbe8d2;
    }
    .error-text {
        color: #b40e0e;
        margin: 0;
        width: 100%;
        font-size: 0.75rem;
    }

    #message {
        height: 10rem;
        margin: 0;
    }
    #check {
        width: auto;
        margin: 0;
    }

    .privacy {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .privacy p {
        margin: 0 0.5rem;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 2rem;
        margin: 0;
        margin-top: 0.5rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: white;
            background: #344f6e;
        }
    }
    .btn:active:enabled {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn:disabled,
    .btn[disabled] {
        border: 1px solid #344f6e50;
        background-color: #344f6e25;
        color: #344f6e50;
    }
    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 3rem;
        margin: 0;
        margin-top: 0.5rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:enabled {
        animation: buttonLeave 0.3s ease-out forwards;
    }
    @keyframes buttonLeave {
        0% {
            color: black;
            background: #344f6e;
            border: 0;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover:enabled {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn-hovered:active:enabled {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn-hovered:disabled,
    .btn-hovered[disabled] {
        border: 1px solid #344f6e50;
        background-color: #344f6e25;
        color: #344f6e50;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }
    .backdrop__open {
        animation: openBackdrop 0.4s ease-out forwards;
    }
    @keyframes openBackdrop {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .backdrop__close {
        animation: closeBackdrop 0.4s ease-in forwards;
    }
    @keyframes closeBackdrop {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .backdrop-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 103;
        background: rgba(0, 0, 0, 0.75);
    }

    .lds-ring {
        display: inline-block;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 104;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #367bc9;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #367bc9 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        box-sizing: border-box;
        top: 30%;
        left: 10%;
        width: 80%;
        height: 20%;
        z-index: 105;
        padding: 3rem;
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        border-radius: 10px;
    }
    .card__open {
        animation: openCard 0.6s ease-out forwards;
    }
    @keyframes openCard {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        50% {
            opacity: 1;
            transform: translateY(90%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .card__close {
        animation: closeCard 0.6s ease-out forwards;
    }
    @keyframes closeCard {
        0% {
            opacity: 1;
            transform: translateY(0);
        }
        50% {
            opacity: 0.8;
            transform: translateY(60%);
        }
        100% {
            opacity: 0;
            transform: translateY(-100%);
        }
    }

    .card p {
        color: #344f6e;
    }

    .form {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 101;
        top: 20%;
        left: 10%;
        width: 80%;
        height: 60%;
        padding: 2rem 3rem;
        margin: 0;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
        background: white;
    }
    .form__open {
        animation: openCard 0.6s ease-out forwards;
    }
    .form__close {
        animation: closeCard 0.6s ease-in forwards;
    }

    .form-control {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .form-control input,
    .form-control textarea {
        font-family: inherit;
        font-size: inherit;
        width: 100%;
        resize: none;
        padding: 0.5rem;
        border-radius: 4px;
        border: 1px solid #ccc;
    }
    .form-control label {
        color: #344f6e;
        font-weight: bold;
    }
    .form-control input:focus,
    .form-control textarea:focus {
        outline: none;
        border-color: #344f6e;
        background-color: #344f6e25;
    }

    .form-control__documents {
        color: #344f6e;
        font-weight: bold;
    }

    .invalid input,
    .invalid textarea {
        border: 1px solid #b40e0e;
        background-color: #fddddd;
    }
    .invalid input:focus,
    .invalid textarea:focus {
        border-color: #ff8800;
        background-color: #fbe8d2;
    }
    .error-text {
        color: #b40e0e;
        margin: 0;
        width: 100%;
        font-size: 0.75rem;
    }

    #message {
        height: 10rem;
        margin: 0;
    }
    #check {
        width: auto;
        margin: 0;
    }

    .privacy {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .privacy p {
        margin: 0 0.5rem;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
    }
    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 2rem;
        margin: 0;
        margin-top: 0.5rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: white;
            background: #344f6e;
        }
    }
    .btn:active:enabled {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn:disabled,
    .btn[disabled] {
        border: 1px solid #344f6e50;
        background-color: #344f6e25;
        color: #344f6e50;
    }
    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 3rem;
        margin: 0;
        margin-top: 0.5rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:enabled {
        animation: buttonLeave 0.3s ease-out forwards;
    }
    @keyframes buttonLeave {
        0% {
            color: black;
            background: #344f6e;
            border: 0;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover:enabled {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn-hovered:active:enabled {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn-hovered:disabled,
    .btn-hovered[disabled] {
        border: 1px solid #344f6e50;
        background-color: #344f6e25;
        color: #344f6e50;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }
    .backdrop__open {
        animation: openBackdrop 0.4s ease-out forwards;
    }
    @keyframes openBackdrop {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .backdrop__close {
        animation: closeBackdrop 0.4s ease-in forwards;
    }
    @keyframes closeBackdrop {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    .backdrop-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 103;
        background: rgba(0, 0, 0, 0.75);
    }

    .lds-ring {
        display: inline-block;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 104;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #367bc9;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #367bc9 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        box-sizing: border-box;
        top: 37.5%;
        left: 30%;
        width: 40%;
        height: 25%;
        z-index: 105;
        padding: 3rem;
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
        border-radius: 10px;
    }
    .card__open {
        animation: openCard 0.6s ease-out forwards;
    }
    @keyframes openCard {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        50% {
            opacity: 1;
            transform: translateY(90%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .card__close {
        animation: closeCard 0.6s ease-out forwards;
    }
    @keyframes closeCard {
        0% {
            opacity: 1;
            transform: translateY(0);
        }
        50% {
            opacity: 0.8;
            transform: translateY(60%);
        }
        100% {
            opacity: 0;
            transform: translateY(-100%);
        }
    }

    .card p {
        color: #344f6e;
    }

    .form {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 101;
        top: 17.5%;
        left: 30%;
        height: 65%;
        min-height: 32.5rem;
        width: 40%;
        padding: 2rem 3rem;
        margin: 0;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.26);
        background: white;
    }
    .form__open {
        animation: openCard 0.6s ease-out forwards;
    }
    .form__close {
        animation: closeCard 0.6s ease-in forwards;
    }

    .form-control {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .form-control input,
    .form-control textarea {
        font-family: inherit;
        font-size: inherit;
        width: 100%;
        resize: none;
        padding: 0.5rem;
        border-radius: 4px;
        border: 1px solid #ccc;
    }
    .form-control label {
        color: #344f6e;
        font-weight: bold;
    }
    .form-control input:focus,
    .form-control textarea:focus {
        outline: none;
        border-color: #344f6e;
        background-color: #344f6e25;
    }

    .form-control__documents {
        color: #344f6e;
        font-weight: bold;
    }

    .invalid input,
    .invalid textarea {
        border: 1px solid #b40e0e;
        background-color: #fddddd;
    }
    .invalid input:focus,
    .invalid textarea:focus {
        border-color: #ff8800;
        background-color: #fbe8d2;
    }
    .error-text {
        color: #b40e0e;
        margin: 0;
        width: 100%;
        font-size: 0.75rem;
    }

    #message {
        height: 10rem;
        margin: 0;
    }
    #check {
        width: auto;
        margin: 0;
    }

    .privacy {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .privacy p {
        margin: 0 0.5rem;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }
    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.5rem 1.5rem;
        margin: 0;
        margin-top: 1rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover:enabled {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: white;
            background: #344f6e;
        }
    }
    .btn:active:enabled {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn:disabled,
    .btn[disabled] {
        border: 1px solid #344f6e50;
        background-color: #344f6e25;
        color: #344f6e50;
    }
    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.5rem 1.5rem;
        margin: 0;
        margin-top: 1rem;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:enabled {
        animation: buttonLeave 0.3s ease-out forwards;
    }
    @keyframes buttonLeave {
        0% {
            color: black;
            background: #344f6e;
            border: 0;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover:enabled {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn-hovered:active:enabled {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn-hovered:disabled,
    .btn-hovered[disabled] {
        border: 1px solid #344f6e50;
        background-color: #344f6e25;
        color: #344f6e50;
    }
}
