/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .main-footer {
        width: 100%;
        background-color: #101e2e;
        padding: 0.5rem 0.5rem;
        display: flex;
        align-items: center;
    }

    .main-footer_brand {
        display: flex;
        height: 3rem;
        align-items: center;
    }
    .main-footer_brand img {
        height: 90%;
        margin: 0;
    }

    .footer-nav {
        width: 100%;
    }
    .footer-nav__items {
        display: flex;
        padding: 0;
        margin: 0;
        justify-content: flex-end;
        list-style: none;
    }
    .footer-nav__item a {
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
        margin: 0 0.5rem;
    }
    .footer-nav__item a:hover,
    .footer-nav__item a:active {
        color: #344f6e;
    }

    .footer-nav__item--hovered a {
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
        margin: 0 0.5rem;
    }
    .footer-nav__item--hovered a:hover,
    .footer-nav__item--hovered a:active {
        color: #344f6e;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .main-footer {
        width: 100%;
        background-color: #101e2e;
        padding: 0.5rem 0.5rem;
        display: flex;
        align-items: center;
    }

    .main-footer_brand {
        display: flex;
        height: 3rem;
        align-items: center;
    }
    .main-footer_brand img {
        height: 85%;
        margin: 0;
    }

    .footer-nav {
        width: 100%;
    }
    .footer-nav__items {
        display: flex;
        padding: 0;
        margin: 0;
        justify-content: flex-end;
        list-style: none;
    }
    .footer-nav__item a {
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
        margin: 0 0.5rem;
    }
    .footer-nav__item a:hover,
    .footer-nav__item a:active {
        color: #344f6e;
    }

    .footer-nav__item--hovered a {
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
        margin: 0 0.5rem;
    }
    .footer-nav__item--hovered a:hover,
    .footer-nav__item--hovered a:active {
        color: #344f6e;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .main-footer {
        width: 100%;
        background-color: #101e2e;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
    }

    .main-footer_brand {
        display: flex;
        height: 3rem;
        align-items: center;
    }
    .main-footer_brand img {
        height: 100%;
        margin: 0;
    }

    .footer-nav {
        width: 100%;
    }
    .footer-nav__items {
        display: flex;
        padding: 0;
        margin: 0;
        justify-content: flex-end;
        list-style: none;
    }
    .footer-nav__item a {
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
        margin: 0 0.5rem;
    }
    .footer-nav__item a:hover,
    .footer-nav__item a:active {
        color: #344f6e;
    }

    .footer-nav__item--hovered a {
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
        margin: 0 0.5rem;
    }
    .footer-nav__item--hovered a:hover,
    .footer-nav__item--hovered a:active {
        color: #344f6e;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .main-footer {
        width: 100%;
        background-color: #101e2e;
        padding: 0.75rem 1rem;
        display: flex;
        align-items: center;
    }
    .main-footer_brand {
        display: flex;
        align-items: center;
        height: 3.5rem;
    }
    .main-footer_brand img {
        height: 100%;
        width: auto;
        margin: 0;
    }

    .footer-nav {
        width: 100%;
    }
    .footer-nav__items {
        display: flex;
        padding: 0;
        margin: 0;
        justify-content: flex-end;
        list-style: none;
    }
    .footer-nav__item a {
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
        margin: 0 1rem;
    }
    .footer-nav__item a:hover {
        animation: itemHover 0.3s ease-out forwards;
    }
    @keyframes itemHover {
        0% {
            color: #367bc9;
        }
        100% {
            color: white;
            border-bottom: 5px solid white;
        }
    }
    .footer-nav__item a:active {
        color: white;
        border-bottom: 5px solid white;
    }
    .footer-nav__item--hovered a {
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
        margin: 0 1rem;
        animation: itemLeave 0.3s ease-in forwards;
    }
    @keyframes itemLeave {
        0% {
            color: white;
            border-bottom: 5px solid white;
        }
        100% {
            color: #367bc9;
            border-bottom: none;
        }
    }
    .footer-nav__item--hovered a:hover {
        animation: itemHover 0.3s ease-out forwards;
    }
    @keyframes itemHover {
        0% {
            color: #367bc9;
        }
        100% {
            color: white;
            border-bottom: 5px solid white;
        }
    }
    .footer-nav__item--hovered a:active {
        color: white;
        border-bottom: 5px solid white;
    }
}
