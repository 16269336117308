/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        margin: 0;
        width: 90%;
    }
    #headline {
        color: #344f6e;
        margin: 0;
        margin-bottom: 1rem;
    }
    #bottomline {
        color: #344f6e;
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 0.25rem;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 3rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover,
    .btn:active {
        background: #344f6e;
        border: 0;
        color: white;
    }

    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 3rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:hover,
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: white;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        margin: 0;
        width: 90%;
    }
    #headline {
        color: #344f6e;
        margin: 0;
        margin-bottom: 1rem;
    }
    #bottomline {
        color: #344f6e;
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 0.25rem;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 3rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover,
    .btn:active {
        background: #344f6e;
        border: 0;
        color: white;
    }

    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 3rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:hover,
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: white;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        margin: 0;
        width: 90%;
    }
    #headline {
        color: #344f6e;
        margin: 0;
        margin-bottom: 1rem;
    }
    #bottomline {
        color: #344f6e;
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 0.25rem;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 3rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover,
    .btn:active {
        background: #344f6e;
        border: 0;
        color: white;
    }

    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 3rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn-hovered:hover,
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: white;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        margin: 0;
        width: 90%;
        max-width: 60rem;
    }
    #headline {
        margin: 0;
        margin-bottom: 2rem;
    }
    #bottomline {
        margin: 0;
        margin-top: 3rem;
        margin-bottom: 0.5rem;
    }

    .btn {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
    }
    .btn:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
    .btn-hovered {
        font: inherit;
        background: #b07531;
        border: 0;
        color: white;
        padding: 0.75rem 4rem;
        margin: 0;
        cursor: pointer;
        font-size: 1rem;
        border-radius: 5px;
        animation: buttonLeave 0.3s ease-in forwards;
    }
    @keyframes buttonLeave {
        0% {
            color: black;
            background: #344f6e;
            border: 0;
        }
        100% {
            color: white;
            background: #b07531;
        }
    }
    .btn-hovered:hover {
        animation: buttonHover 0.3s ease-out forwards;
    }
    @keyframes buttonHover {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: black;
            background: #344f6e;
            border: 0;
        }
    }
    .btn-hovered:active {
        background: #344f6e;
        border: 0;
        color: black;
    }
}
