/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        width: 90%;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .description h2 {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .description p {
        margin: 0;
        margin-bottom: 2rem;
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 2rem;
        line-height: 1rem;
    }
    .contact-address {
        list-style: none;
        width: 10rem;
        padding: 0;
        margin: 0;
    }
    .contact-address__element {
        color: #367bc9;
    }
    .contact__icon {
        vertical-align: middle;
        cursor: pointer;
        color: #367bc9;
    }

    .contact__icon--clicked {
        vertical-align: middle;
        cursor: pointer;
        color: #b07531;
    }

    .contact-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        height: 5rem;
    }

    .contact-element {
        text-decoration: none;
        width: 10rem;
        text-align: center;
        color: #367bc9;
        border: 1px solid #b07531;
        border-radius: 5px;
        padding: 0.5rem 0.5rem;
    }
    .contact-element:active {
        background: #b07531;
        color: white;
    }
    .contact-element__open {
        animation: openElement 0.6s ease-out forwards;
    }
    @keyframes openElement {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .contact-element__close {
        animation: closeElement 0.6s ease-in forwards;
    }
    @keyframes closeElement {
        from {
            opacity: 1;
            transform: translateX(0);
        }
        to {
            opacity: 0;
            transform: translateX(100%);
        }
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
        width: 90%;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .description h2 {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .description p {
        margin: 0;
        margin-bottom: 2rem;
    }

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 2rem;
        line-height: 1rem;
    }
    .contact-address {
        list-style: none;
        width: 10rem;
        padding: 0;
        margin: 0;
    }
    .contact-address__element {
        color: #367bc9;
    }
    .contact__icon {
        vertical-align: middle;
        cursor: pointer;
        color: #367bc9;
    }

    .contact__icon--clicked {
        vertical-align: middle;
        cursor: pointer;
        color: #b07531;
    }

    .contact-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        height: 5rem;
    }

    .contact-element {
        text-decoration: none;
        width: 10rem;
        text-align: center;
        color: #367bc9;
        border: 1px solid #b07531;
        border-radius: 5px;
        padding: 0.5rem 0.5rem;
    }
    .contact-element:active {
        background: #b07531;
        color: white;
    }
    .contact-element__open {
        animation: openElement 0.6s ease-out forwards;
    }
    @keyframes openElement {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .contact-element__close {
        animation: closeElement 0.6s ease-in forwards;
    }
    @keyframes closeElement {
        from {
            opacity: 1;
            transform: translateX(0);
        }
        to {
            opacity: 0;
            transform: translateX(100%);
        }
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sect {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: 3rem 0;
        width: 90%;
        max-width: 60rem;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .description h2 {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .description p {
        margin: 0;
        margin-bottom: 2rem;
    }

    .body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        width: 15rem;
        line-height: 1;
        padding: 0;
        margin: 0;
    }
    .contact-address {
        list-style: none;
        width: 10rem;
        padding: 0;
        margin: 0;
    }
    .contact-address__element {
        color: #367bc9;
    }
    .contact__icon {
        vertical-align: middle;
        cursor: pointer;
        color: #367bc9;
    }

    .contact__icon--clicked {
        vertical-align: middle;
        cursor: pointer;
        color: #b07531;
    }

    .contact-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        height: 5rem;
    }

    .contact-element {
        text-decoration: none;
        width: 10rem;
        text-align: center;
        color: #367bc9;
        border: 1px solid #b07531;
        border-radius: 5px;
        padding: 0.5rem 0.5rem;
    }
    .contact-element:active {
        background: #b07531;
        color: white;
    }
    .contact-element__open {
        animation: openElement 0.6s ease-out forwards;
    }
    @keyframes openElement {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .contact-element__close {
        animation: closeElement 0.6s ease-in forwards;
    }
    @keyframes closeElement {
        from {
            opacity: 1;
            transform: translateX(0);
        }
        to {
            opacity: 0;
            transform: translateX(100%);
        }
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sect {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: 3rem 0;
        width: 90%;
        max-width: 60rem;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .description h2 {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .description p {
        margin: 0;
        margin-bottom: 2rem;
    }

    .body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        width: 15rem;
        line-height: 1;
        padding: 0;
        margin: 0;
    }
    .contact-address {
        list-style: none;
        width: 10rem;
        margin: 0;
        padding: 0;
    }
    .contact-address__element {
        color: #344f6e;
        text-decoration: none;
    }

    .contact__icon {
        vertical-align: middle;
        cursor: pointer;
        color: #367bc9;
    }
    .contact__icon:hover {
        animation: iconHover 0.3s ease-out forwards;
    }
    @keyframes iconHover {
        from {
            color: #367bc9;
        }
        to {
            color: #b07531;
        }
    }
    .contact__icon--clicked {
        vertical-align: middle;
        cursor: pointer;
        color: #b07531;
    }
    .contact__icon--clicked:hover {
        animation: iconHoverClicked 0.3s ease-out forwards;
    }
    @keyframes iconHoverClicked {
        from {
            color: #b07531;
        }
        to {
            color: #367bc9;
        }
    }

    .hovered {
        animation: iconLeave 0.3s ease-in forwards;
    }
    @keyframes iconLeave {
        from {
            color: #b07531;
        }
        to {
            color: #367bc9;
        }
    }
    .hovered--clicked {
        animation: iconLeaveClicked 0.3s ease-in forwards;
    }
    @keyframes iconLeaveClicked {
        from {
            color: #367bc9;
        }
        to {
            color: #b07531;
        }
    }

    .contact-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        height: 5rem;
    }

    .contact-element {
        text-decoration: none;
        width: 10rem;
        text-align: left;
        color: #344f6e;
        border: none;
        padding: 0;
    }
    .contact-element__open {
        animation: openElement 0.6s ease-out forwards;
    }
    @keyframes openElement {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .contact-element__close {
        animation: closeElement 0.6s ease-in forwards;
    }
    @keyframes closeElement {
        from {
            opacity: 1;
            transform: translateX(0);
        }
        to {
            opacity: 0;
            transform: translateX(100%);
        }
    }
}
