.arrowBox {
    display: none;
}

@media (min-width: 55rem) {
    .arrowBox {
        border: 1px solid #344f6e;
        border-radius: 3px;
        padding: 0.7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #344f6e;
        margin: 0 0.5rem;
        width: auto;
        cursor: pointer;
    }
    .arrowBox:hover {
        animation: changeBoxColor 0.3s ease-out forwards;
    }
    @keyframes changeBoxColor {
        0% {
            background: #344f6e;
            border-color: #344f6e;
        }
        100% {
            background: #b07531;
            border-color: #b07531;
        }
    }

    .arrowBox-hovered {
        border: 1px solid #344f6e;
        border-radius: 3px;
        padding: 0.7rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #344f6e;
        margin: 0 0.5rem;
        width: auto;
        cursor: pointer;
        animation: leaveBox 0.3s ease-in forwards;
    }
    @keyframes leaveBox {
        0% {
            background: #b07531;
            border-color: #b07531;
        }
        100% {
            background: #344f6e;
            border-color: #344f6e;
        }
    }
    .arrowBox-hovered:hover {
        animation: hoverBox 0.3s ease-out forwards;
    }
    @keyframes hoverBox {
        0% {
            background: #344f6e;
            border-color: #344f6e;
        }
        100% {
            background: #b07531;
            border-color: #b07531;
        }
    }

    .arrow {
        border: solid white;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}
