/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .currentProducts,
    .pastProducts {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .currentProducts__head,
    .pastProducts__head {
        color: #344f6e;
        text-align: center;
        margin: 0;
        margin-bottom: 2rem;
        padding: 0;
    }
    .currentProducts__text,
    .pastProducts__text {
        margin: 0;
        padding: 0;
    }
    .pastProducts__text {
        margin-bottom: 2rem;
    }

    .products {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .products__text {
        color: white;
        margin: 0;
        margin-bottom: 2rem;
    }
    .products-specs {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 100%;
        margin: 0;
        margin-bottom: 2rem;
    }
    .products-specs__element {
        width: 100%;
        height: 7rem;
        line-height: 1.1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: white;
    }
    .products-specs__element h3,
    .products-specs__element p {
        margin: 0;
    }
    .products-specs__element__icon {
        color: #b07531;
        vertical-align: middle;
    }
    .products-specs__element__icon--clicked {
        color: #367bc9;
        vertical-align: middle;
    }
    .products-specs__element__text {
        width: 80%;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .currentProducts,
    .pastProducts {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .currentProducts__head,
    .pastProducts__head {
        color: #344f6e;
        text-align: center;
        margin: 0;
        margin-bottom: 2rem;
        padding: 0;
    }
    .currentProducts__text,
    .pastProducts__text {
        margin: 0;
        padding: 0;
    }
    .pastProducts__text {
        margin-bottom: 2rem;
    }

    .products {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        width: 90%;
    }
    .products__text {
        color: white;
        margin: 0;
        margin-bottom: 2rem;
    }
    .products-specs {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 100%;
        margin: 0;
        margin-bottom: 2rem;
    }
    .products-specs__element {
        width: 100%;
        height: 7rem;
        line-height: 1.1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: white;
    }
    .products-specs__element h3,
    .products-specs__element p {
        margin: 0;
    }
    .products-specs__element__icon {
        color: #b07531;
        vertical-align: middle;
    }
    .products-specs__element__icon--clicked {
        color: #367bc9;
        vertical-align: middle;
    }
    .products-specs__element__text {
        width: 80%;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .background__dark {
        display: flex;
        justify-content: space-around;
        background: #344f6e;
        padding: 3rem 0;
    }
    .background__light {
        display: flex;
        margin: auto;
        justify-content: space-around;
        padding: 3rem 0;
    }

    .currentProducts,
    .pastProducts {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 60rem;
    }
    .currentProducts__head,
    .pastProducts__head {
        color: #344f6e;
        margin: 0;
        margin-bottom: 2rem;
        padding: 0;
    }
    .currentProducts__text,
    .pastProducts__text {
        margin: 0;
        padding: 0;
    }
    .pastProducts__text {
        margin-bottom: 2rem;
    }

    .products {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 60rem;
        margin: 0;
        padding: 0;
    }
    .products__head {
        color: #b07531;
        margin: 0;
        margin-bottom: 0.5rem;
    }
    .products__text {
        color: white;
        margin: 0;
        margin-bottom: 2rem;
    }

    .products-specs {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 2rem;
    }
    .products-specs__element {
        width: 50%;
        height: 8rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        color: white;
    }
    .products-specs__element h3,
    .products-specs__element p {
        margin: 0;
    }
    .products-specs__element__icon {
        color: #b07531;
        vertical-align: middle;
    }
    .products-specs__element__icon--clicked {
        color: #367bc9;
        vertical-align: middle;
    }
    .products-specs__element__text {
        width: 80%;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .background__dark {
        display: flex;
        justify-content: space-around;
        background: #344f6e;
        padding: 3rem 0;
    }
    .background__light {
        display: flex;
        margin: auto;
        justify-content: space-around;
        padding: 3rem 0;
    }

    .currentProducts,
    .pastProducts {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 60rem;
    }
    .currentProducts__head,
    .pastProducts__head {
        color: #344f6e;
        margin: 0;
        margin-bottom: 2rem;
        padding: 0;
    }
    .currentProducts__text,
    .pastProducts__text {
        margin: 0;
        padding: 0;
    }
    .pastProducts__text {
        margin-bottom: 2rem;
    }

    .products {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 60rem;
        margin: 0;
        padding: 0;
    }
    .products__head {
        color: #b07531;
        margin: 0;
        margin-bottom: 2rem;
    }
    .products__text {
        color: white;
        margin: 0;
        margin-bottom: 2rem;
    }

    .products-specs {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 2rem;
    }
    .products-specs__element {
        width: 50%;
        height: 7.5rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        color: white;
    }
    .products-specs__element h3,
    .products-specs__element p {
        margin: 0;
    }
    .products-specs__element__icon {
        vertical-align: middle;
        color: #b07531;
        cursor: pointer;
        animation: iconLeave 0.3s ease-in forwards;
    }
    .products-specs__element__icon--clicked {
        color: #367bc9;
        vertical-align: middle;
    }
    @keyframes productsIconLeave {
        0% {
            color: #367bc9;
        }
        100% {
            color: #b07531;
        }
    }
    .products-specs__element__icon:hover {
        animation: productsIconHover 0.3s ease-out forwards;
    }
    @keyframes productsIconHover {
        0% {
            color: #b07531;
        }
        100% {
            color: #367bc9;
        }
    }
    .products-specs__element__icon--clicked {
        vertical-align: middle;
        cursor: pointer;
        animation: productsIconLeaveOff 0.3s ease-in forwards;
    }
    @keyframes productsIconLeaveOff {
        0% {
            color: #b07531;
        }
        100% {
            color: #367bc9;
        }
    }
    .products-specs__element__icon--clicked:hover {
        animation: productsIconHoverOff 0.3s ease-out forwards;
    }
    @keyframes productsIconHoverOff {
        0% {
            color: #367bc9;
        }
        100% {
            color: #b07531;
        }
    }
    .products-specs__element__text {
        width: 80%;
    }
}
