/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .main-nav {
        display: none;
    }

    .main-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: #101e2e;
        padding: 0.5rem 0.5rem;
    }

    .toggle-button {
        width: 3rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0.5rem;
    }

    .toggle-button:focus {
        outline: none;
    }

    .toggle-button__bar {
        width: 100%;
        height: 0.2rem;
        background: #344f6e;
        display: block;
        margin: 0.5rem 0;
    }

    .mobile-nav__exit {
        background: transparent;
        cursor: pointer;
        margin: 0;
        margin-top: 0.5rem;
        margin-right: 1rem;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
        border: 3px solid #367bc9;
        border-radius: 8px;
        z-index: 102;
    }
    .mobile-nav__button--x {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
    }
    .mobile-nav__button--x::before,
    .mobile-nav__button--x::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 30px;
        background-color: #367bc9;
    }
    .mobile-nav__button--x::before {
        transform: rotate(45deg);
    }
    .mobile-nav__button--x::after {
        transform: rotate(-45deg);
    }

    .main-header_brand {
        display: flex;
        height: 3rem;
        align-items: center;
        padding-left: 1rem;
    }
    .main-header_brand img {
        height: 90%;
        margin: 0;
    }

    .mobile-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }
    .mobile-backdrop__open {
        animation: openBackdrop 0.4s ease-out forwards;
    }
    @keyframes openBackdrop {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .mobile-backdrop__close {
        animation: closeBackdrop 0.4s ease-in forwards;
    }
    @keyframes closeBackdrop {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .mobile-nav {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 101;
        top: 0;
        right: 0;
        width: 70%;
        height: 100vh;
        background-color: #344f6e;
        border-right: 0.1rem solid #101e2e;
        transition: all 0.4s ease-out;
    }
    .mobile-nav__open {
        animation: openNav 0.4s ease-out forwards;
    }
    @keyframes openNav {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        10% {
            opacity: 1;
        }
        70% {
            opacity: 1;
            transform: translateX(-10%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .mobile-nav__close {
        animation: closeNav 0.4s ease-in forwards;
    }
    @keyframes closeNav {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
        30% {
            opacity: 1;
            transform: translateX(-10%);
        }
        90% {
            opacity: 0.5;
        }
        100% {
            opacity: 0;
            transform: translateX(100%);
        }
    }

    .mobile-nav__items {
        width: 90%;
        height: 100%;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        text-align: center;
    }
    .mobile-nav__item {
        margin: 1rem 0;
    }
    .mobile-nav__item a {
        font-size: 1.5rem;
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
    }
    .mobile-nav__item a:hover,
    .mobile-nav__item a:active {
        color: #367bc9;
    }

    .mobile-nav__item--cta a {
        color: white;
        background: #b07531;
        padding: 0.5rem 1rem;
        border-radius: 8px;
    }
    .mobile-nav__item--cta a:hover,
    .mobile-nav__item--cta a:active {
        color: #101e2e;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .main-nav {
        display: none;
    }

    .main-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: #101e2e;
        padding: 0.5rem 0.5rem;
    }

    .toggle-button {
        width: 3rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0.5rem;
    }
    .toggle-button:focus {
        outline: none;
    }
    .toggle-button__bar {
        width: 100%;
        height: 0.2rem;
        background: #344f6e;
        display: block;
        margin: 0.5rem 0;
    }

    .mobile-nav__exit {
        background: transparent;
        cursor: pointer;
        margin: 0;
        margin-top: 0.5rem;
        margin-right: 1rem;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
        border: 3px solid #367bc9;
        border-radius: 8px;
        z-index: 102;
    }
    .mobile-nav__button--x {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
    }
    .mobile-nav__button--x::before,
    .mobile-nav__button--x::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 30px;
        background-color: #367bc9;
    }
    .mobile-nav__button--x::before {
        transform: rotate(45deg);
    }
    .mobile-nav__button--x::after {
        transform: rotate(-45deg);
    }

    .main-header_brand {
        display: flex;
        height: 3rem;
        align-items: center;
    }
    .main-header_brand img {
        height: 85%;
        margin: 0;
    }

    .mobile-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }
    .mobile-backdrop__open {
        animation: openBackdrop 0.4s ease-out forwards;
    }
    @keyframes openBackdrop {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .mobile-backdrop__close {
        animation: closeBackdrop 0.4s ease-in forwards;
    }
    @keyframes closeBackdrop {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .mobile-nav {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 101;
        top: 0;
        right: 0;
        width: 80%;
        height: 100vh;
        background-color: #344f6e;
        border-right: 0.1rem solid #101e2e;
        transition: all 0.4s ease-out;
    }
    .mobile-nav__open {
        animation: openNav 0.4s ease-out forwards;
    }
    @keyframes openNav {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        10% {
            opacity: 1;
        }
        70% {
            opacity: 1;
            transform: translateX(-10%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .mobile-nav__close {
        animation: closeNav 0.4s ease-in forwards;
    }
    @keyframes closeNav {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
        30% {
            opacity: 1;
            transform: translateX(-10%);
        }
        90% {
            opacity: 0.5;
        }
        100% {
            opacity: 0;
            transform: translateX(100%);
        }
    }

    .mobile-nav__items {
        width: 90%;
        height: 100%;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        text-align: center;
    }
    .mobile-nav__item {
        margin: 1rem 0;
    }
    .mobile-nav__item a {
        font-size: 1.5rem;
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
    }
    .mobile-nav__item a:hover,
    .mobile-nav__item a:active {
        color: #367bc9;
    }

    .mobile-nav__item--cta a {
        color: white;
        background: #b07531;
        padding: 0.5rem 1rem;
        border-radius: 8px;
    }
    .mobile-nav__item--cta a:hover,
    .mobile-nav__item--cta a:active {
        color: #101e2e;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .main-nav {
        display: none;
    }

    .main-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: #101e2e;
        padding: 0.5rem 1rem;
    }

    .toggle-button {
        width: 3rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0.5rem;
    }

    .toggle-button:focus {
        outline: none;
    }

    .toggle-button__bar {
        width: 100%;
        height: 0.2rem;
        background: #344f6e;
        display: block;
        margin: 0.5rem 0;
    }

    .mobile-nav__exit {
        background: transparent;
        cursor: pointer;
        margin: 0;
        margin-top: 0.5rem;
        margin-right: 1rem;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
        border: 3px solid #367bc9;
        border-radius: 8px;
        z-index: 102;
    }
    .mobile-nav__button--x {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
    }
    .mobile-nav__button--x::before,
    .mobile-nav__button--x::after {
        content: "";
        position: absolute;
        width: 3px;
        height: 30px;
        background-color: #367bc9;
    }
    .mobile-nav__button--x::before {
        transform: rotate(45deg);
    }
    .mobile-nav__button--x::after {
        transform: rotate(-45deg);
    }

    .main-header_brand {
        display: flex;
        height: 3rem;
        align-items: center;
        padding-left: 1rem;
    }
    .main-header_brand img {
        height: 100%;
        margin: 0;
    }

    .mobile-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 100;
        background: rgba(0, 0, 0, 0.75);
    }
    .mobile-backdrop__open {
        animation: openBackdrop 0.4s ease-out forwards;
    }
    @keyframes openBackdrop {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .mobile-backdrop__close {
        animation: closeBackdrop 0.4s ease-in forwards;
    }
    @keyframes closeBackdrop {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .mobile-nav {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 101;
        top: 0;
        right: 0;
        width: 50%;
        height: 100vh;
        background-color: #344f6e;
        border-right: 0.1rem solid #101e2e;
        transition: all 0.4s ease-out;
    }
    .mobile-nav__open {
        animation: openNav 0.4s ease-out forwards;
    }
    @keyframes openNav {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }
        10% {
            opacity: 1;
        }
        70% {
            opacity: 1;
            transform: translateX(-10%);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .mobile-nav__close {
        animation: closeNav 0.4s ease-in forwards;
    }
    @keyframes closeNav {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
        30% {
            opacity: 1;
            transform: translateX(-10%);
        }
        90% {
            opacity: 0.5;
        }
        100% {
            opacity: 0;
            transform: translateX(100%);
        }
    }

    .mobile-nav__items {
        width: 90%;
        height: 100%;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        text-align: center;
    }
    .mobile-nav__item {
        margin: 1rem 0;
    }
    .mobile-nav__item a {
        font-size: 1.5rem;
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
    }
    .mobile-nav__item a:hover,
    .mobile-nav__item a:active {
        color: #367bc9;
    }

    .mobile-nav__item--cta a {
        color: white;
        background: #b07531;
        padding: 0.5rem 1rem;
        border-radius: 8px;
    }
    .mobile-nav__item--cta a:hover,
    .mobile-nav__item--cta a:active {
        color: #101e2e;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .toggle-button,
    .mobile-nav {
        display: none;
    }

    .main-header {
        width: 100%;
        background: #101e2e;
        padding: 0.75rem 1rem;
        display: flex;
        align-items: center;
    }

    .main-header_brand {
        display: flex;
        height: 3.5rem;
        align-items: center;
        padding-left: 1rem;
    }
    .main-header_brand img {
        height: 100%;
        margin: 0;
    }

    .main-nav {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .main-nav__items {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
    }
    .main-nav__item {
        margin: 1rem;
    }
    .main-nav a {
        text-decoration: none;
        color: #367bc9;
        font-weight: bold;
        padding: 3px 0;
    }
    .main-nav__item a:hover {
        animation: hoverItem 0.3s ease-out forwards;
    }
    @keyframes hoverItem {
        0% {
            color: #367bc9;
        }
        100% {
            color: white;
            border-bottom: 5px solid white;
        }
    }
    .main-nav__item a:active {
        color: white;
        border-bottom: 5px solid white;
    }
    .main-nav__item--cta a {
        color: white;
        background: #b07531;
        padding: 0.5rem 1rem;
        border-radius: 8px;
    }
    .main-nav__item--cta a:hover {
        animation: hoverContact 0.3s ease-out forwards;
    }
    @keyframes hoverContact {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: white;
            border: none;
        }
    }
    .main-nav__item--cta a:active {
        color: #b07531;
        background: white;
        border: none;
    }

    .main-nav__item--hovered {
        margin: 1rem;
    }
    .main-nav__item--hovered a {
        animation: leaveItem 0.3s ease-in forwards;
    }
    @keyframes leaveItem {
        100% {
            color: #367bc9;
        }
        0% {
            color: white;
            border-bottom: 5px solid white;
        }
    }

    .main-nav__item--hovered a:hover {
        animation: hoverItem 0.3s ease-out forwards;
    }
    @keyframes hoverItem {
        0% {
            color: #367bc9;
        }
        100% {
            color: white;
            border-bottom: 5px solid white;
        }
    }
    .main-nav__item--hovered a:active {
        color: white;
        border-bottom: 5px solid white;
    }

    .main-nav__item--cta--hovered a {
        color: white;
        background: #b07531;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        animation: leaveContact 0.3s ease-in forwards;
    }
    @keyframes leaveContact {
        100% {
            color: white;
            background: #b07531;
        }
        0% {
            color: #b07531;
            background: white;
            border: none;
        }
    }
    .main-nav__item--cta--hovered a:hover {
        animation: hoverContact 0.3s ease-out forwards;
    }
    @keyframes hoverContact {
        0% {
            color: white;
            background: #b07531;
        }
        100% {
            color: #b07531;
            background: white;
            border: none;
        }
    }
    .main-nav__item--cta--hovered a:active {
        color: #b07531;
        background: white;
        border: none;
    }
}
