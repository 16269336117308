.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
    height: 4rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #b07531;
    cursor: pointer;
}
.container-hovered {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
    height: 4rem;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #b07531;
    cursor: pointer;
    animation: leaveInput 0.3s ease-in forwards;
}
@keyframes leaveInput {
    0% {
        border-color: #344f6e;
        background-color: #344f6e25;
    }
    100% {
        border-color: #b07531;
        background-color: transparent;
    }
}
.container-hovered:hover {
    animation: hoverInput 0.3s ease-out forwards;
}
@keyframes hoverInput {
    0% {
        border-color: #b07531;
        background-color: transparent;
    }
    100% {
        border-color: #344f6e;
        background-color: #344f6e25;
    }
}
.container-hovered:active {
    border-color: #344f6e;
    background-color: #344f6e25;
}

.input {
    display: none;
}

.label {
    color: #b07531;
    margin: 0;
    margin-bottom: 0.5rem;
}
.text {
    color: black;
    font-weight: normal;
    font-size: 0.75rem;
    margin: 0;
}
