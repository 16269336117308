/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        padding: 3rem 0;
    }
    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .service__image {
        background: transparent;
        margin: 0;
        margin-bottom: 2rem;
        height: auto;
        width: 100%;
    }
    .service__image img {
        width: 100%;
        height: auto;
        margin: 0;
    }

    .analisis,
    .development,
    .production {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .analisis__head,
    .production__head {
        width: 100%;
        text-align: center;
        margin: 0;
        color: #344f6e;
    }
    .production__head {
        margin-bottom: 2rem;
    }
    
    .analisis-block,
    .production-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0;
    }
    .analisis-block__head,
    .production-block__head {
        margin: 0;
        margin-bottom: 0.25rem;
        margin-top: 2rem;
        color: #b07531;
    }
    .analisis-block__text,
    .production-block__text {
        margin: 0;
        padding: 0;
    }

    .development__head {
        width: 100%;
        text-align: center;
        margin: 0;
        color: #b07531;
    }
    .development-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0;
    }
    .development-block__head {
        margin: 0;
        margin-bottom: 0.25rem;
        margin-top: 2rem;
        color: #b07531;
    }
    .development-block__text {
        margin: 0;
        padding: 0;
        color: white;
    }
    .development-block__text--last {
        margin-bottom: 2rem;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        padding: 3rem 0;
    }
    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .service__image {
        background: transparent;
        margin: 0;
        margin-bottom: 2rem;
        height: auto;
        width: 100%;
    }
    .service__image img {
        width: 100%;
        height: auto;
        margin: 0;
    }

    .analisis,
    .development,
    .production {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .analisis__head,
    .production__head {
        width: 100%;
        text-align: center;
        margin: 0;
        color: #344f6e;
    }
    .production__head {
        margin-bottom: 2rem;
    }

    .analisis-block,
    .production-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0;
    }
    .analisis-block__head,
    .production-block__head {
        margin: 0;
        margin-bottom: 0.25rem;
        margin-top: 2rem;
        color: #b07531;
    }
    .analisis-block__text,
    .production-block__text {
        margin: 0;
        padding: 0;
    }

    .development__head {
        width: 100%;
        text-align: center;
        margin: 0;
        color: #b07531;
    }
    .development-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0;
    }
    .development-block__head {
        margin: 0;
        margin-bottom: 0.25rem;
        margin-top: 2rem;
        color: #b07531;
    }
    .development-block__text {
        margin: 0;
        padding: 0;
        color: white;
    }
    .development-block__text--last {
        margin-bottom: 2rem;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        padding: 3rem 0;
    }
    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .service__image {
        background: transparent;
        margin: 0;
        margin-bottom: 2rem;
        height: auto;
        width: 100%;
    }
    .service__image img {
        width: 100%;
        height: auto;
        margin: 0;
    }

    .analisis,
    .development,
    .production {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
    }
    .analisis__head,
    .production__head {
        width: 100%;
        text-align: center;
        margin: 0;
        color: #344f6e;
    }
    .production__head {
        margin-bottom: 2rem;
    }

    .analisis-block,
    .production-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0;
    }
    .analisis-block__head,
    .production-block__head {
        margin: 0;
        margin-bottom: 0.25rem;
        margin-top: 2rem;
        color: #b07531;
    }
    .analisis-block__text,
    .production-block__text {
        margin: 0;
        padding: 0;
    }

    .development__head {
        width: 100%;
        text-align: center;
        margin: 0;
        color: #b07531;
    }
    .development-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0;
    }
    .development-block__head {
        margin: 0;
        margin-bottom: 0.25rem;
        margin-top: 2rem;
        color: #b07531;
    }
    .development-block__text {
        margin: 0;
        padding: 0;
        color: white;
    }
    .development-block__text--last {
        margin-bottom: 2rem;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .background__light {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .background__dark {
        background: #344f6e;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }

    .service__image {
        background: transparent;
        margin: 0;
        margin-bottom: 3rem;
        padding: 0;
        width: 90%;
        max-width: 60rem;
        height: auto;
    }
    .service__image img {
        width: 100%;
        height: auto;
        margin: 0;
    }

    .analisis,
    .development,
    .production {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 60rem;
    }
    .analisis__head,
    .production__head {
        width: 100%;
        text-align: center;
        margin: 0;
        color: #344f6e;
    }
    .production__head {
        margin-bottom: 2rem;
    }

    .analisis-block,
    .production-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0;
    }
    .analisis-block__head,
    .production-block__head {
        margin: 0;
        margin-bottom: 0.5rem;
        margin-top: 2rem;
        color: #b07531;
    }
    .analisis-block__text,
    .production-block__text {
        margin: 0;
        padding: 0;
    }

    .development__head {
        width: 100%;
        text-align: center;
        margin: 0;
        color: #b07531;
    }
    .development-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        margin: 0;
    }
    .development-block__head {
        margin: 0;
        margin-bottom: 0.5rem;
        margin-top: 2rem;
        color: #b07531;
    }
    .development-block__text {
        margin: 0;
        padding: 0;
        color: white;
    }
    .development-block__text--last {
        margin-bottom: 2rem;
    }
}
