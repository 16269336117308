/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
    }

    .privacyNotice__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 90%;
    }

    .card {
        margin: 0.5rem 0;
        padding: 1rem 1rem;
        width: 90%;
        max-width: 60rem;
        border-radius: 10px;
    }
    .card h4 {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.25rem;
    }
    .card p {
        margin: 0;
    }
    .headline {
        color: #344f6e;
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        padding: 0;
    }
    .subheadline {
        color: #b07531;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0.25rem;
        padding: 0;
        text-align: left;
        width: 90%;
    }
    .text {
        width: 90%;
        margin: 0;
        padding: 0;
    }
    .list {
        width: 90%;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
    }

    .privacyNotice__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 90%;
        max-width: 60rem;
    }

    .card {
        margin: 0.5rem 0;
        padding: 1rem 1rem;
        width: 90%;
        max-width: 60rem;
        border-radius: 10px;
    }
    .card h4 {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.25rem;
    }
    .card p {
        margin: 0;
    }
    .headline {
        color: #344f6e;
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        padding: 0;
    }
    .subheadline {
        color: #b07531;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0.25rem;
        padding: 0;
        text-align: left;
        width: 90%;
    }
    .text {
        width: 90%;
        margin: 0;
        padding: 0;
    }
    .list {
        width: 90%;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
    }

    .privacyNotice__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 90%;
        max-width: 60rem;
    }

    .card {
        margin: 0.5rem 0;
        padding: 1rem 1rem;
        width: 90%;
        max-width: 60rem;
        border-radius: 10px;
    }
    .card h4 {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.25rem;
    }
    .card p {
        margin: 0;
    }
    .headline {
        color: #344f6e;
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        padding: 0;
    }
    .subheadline {
        color: #b07531;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0.25rem;
        padding: 0;
        text-align: left;
        width: 90%;
    }
    .text {
        width: 90%;
        margin: 0;
        padding: 0;
    }
    .list {
        width: 90%;
    }
}

/* PC/Laptop ************************************************************************************************************************************/
@media (min-width: 55rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
    }

    .privacyNotice__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        width: 90%;
        max-width: 60rem;
    }

    .card {
        margin: 0.5rem 0;
        padding: 1rem 1rem;
        width: 90%;
        max-width: 60rem;
        border-radius: 10px;
    }
    .card h4 {
        color: #344f6e;
        margin: 0;
        margin-bottom: 0.25rem;
    }
    .card p {
        margin: 0;
    }
    .headline {
        color: #344f6e;
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        padding: 0;
    }
    .subheadline {
        color: #b07531;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0.25rem;
        padding: 0;
        text-align: left;
        width: 90%;
    }
    .text {
        width: 90%;
        margin: 0;
        padding: 0;
    }
    .list {
        width: 90%;
    }
}
