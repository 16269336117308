/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    * {
        box-sizing: border-box;
    }

    html {
        background: #fff;
    }

    body {
        font-family: "HelveticaNeue-Light", "Helvetica Neue Light",
            "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
        margin: 0;
        line-height: 1.3;
    }

    main {
        margin-top: 6rem;
    }
}

/* Alles außer kleine Smartphones **********************************************************************/
@media (min-width: 24rem) {
    * {
        box-sizing: border-box;
    }

    html {
        background: #fff;
    }

    body {
        font-family: "HelveticaNeue-Light", "Helvetica Neue Light",
            "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
        margin: 0;
        line-height: 1.5;
    }

    main {
        margin-top: 6rem;
    }
}
