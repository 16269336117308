/* Kleine Smartphones *************************************************************************************************************************/
@media (max-width: 23.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: 0;
        padding: 3rem 0;
        width: 90%;
    }

    .team-departement {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0;
        margin-bottom: 3rem;
    }
    .team-departement__last {
        margin: 0;
    }
}

/* Große Smartphones **************************************************************************************************************************/
@media (min-width: 24rem) and (max-width: 29.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: 0;
        padding: 3rem 0;
        width: 90%;
    }

    .team-departement {
        display: flex;
        justify-self: center;
        width: 100%;
        margin: 0;
        margin-bottom: 3rem;
    }
    .team-departement__last {
        margin: 0;
    }
}

/* Tablets ************************************************************************************************************************************/
@media (min-width: 30rem) and (max-width: 54.9rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: 0;
        padding: 3rem 0;
        width: 95%;
    }

    .team-departement {
        display: flex;
        width: 100%;
        margin: 0;
        margin-bottom: 3rem;
    }
    .team-departement__last {
        margin: 0;
    }
}

/* PCs / Laptops **********************************************************************/
@media (min-width: 55rem) {
    .background {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sect {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: 0;
        padding: 3rem 0;
        width: 90%;
        max-width: 60rem;
    }

    .team-departement {
        display: flex;
        width: 100%;
        margin: 0;
        margin-bottom: 3rem;
    }
    .team-departement__last {
        margin: 0;
    }
}
